<template>
  <div :class="modalContent"><slot /></div>
</template>
<script>
import { MODAL_CONTENT_COMPONENT, EMPTY_STRING } from '@/constants'

const CSN_MODAL_CONTENT = 'csn-modal-content'
const CSN_MODAL_CONTENT_ACTIVE = 'csn-modal-content-active'

export default {
  name: MODAL_CONTENT_COMPONENT,
  props: {
    isActive: Boolean,
    isRemovalPending: Boolean,
  },
  computed: {
    modalContent() {
      return [
        CSN_MODAL_CONTENT,
        this.isActive && !this.isRemovalPending
          ? CSN_MODAL_CONTENT_ACTIVE
          : EMPTY_STRING,
      ]
    },
  },
}
</script>
